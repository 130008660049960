.recipe-page {

    .recipe-loader {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .recipe-header-controls {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        svg {
            font-size: 18pt;
        }

        button {
            display: flex;
            align-items: center;
            padding: 0 25px;
            text-decoration: none;
        }

        .back-button {
            svg {
                font-size: 22pt;
                margin-right: 5px;
            }
        }
    }

    

    a {
        text-decoration: none;
        display: inline-block;
    }

    h2 {
        font-weight: 300;
        font-size: 36pt;
        margin-top: 20px;
        margin-bottom: 5px;
    }

    .author-name {
        font-weight: 600;
    }

    .recipe-author {
        margin-left: 5px;
    }

    .recipe-chips {
        margin: 10px 0 10px 5px;
        display: flex;
        max-width: 100%;
        flex-wrap: wrap;

        .chip {
            margin-right: 5px;
            margin-bottom: 5px;
            display: flex;
            height: 13px;
            align-items: center;
            justify-content: center;

            &.expandable {
                width: fit-content;
                padding-right: 8px;
                cursor: pointer;
                transition: all 0.4s ease;
                white-space: nowrap;
    
                svg {
                    font-size: 14pt;
                }

                &#expanded {
                    height: fit-content;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    line-height: 16pt;

                    svg {
                        font-size: 12pt;
                        margin-left: 5px;
                    }
                }
            }

            &.scale {
                display: flex;
                flex-direction: column;
                text-align: left;
                width: calc(50% - 40px);
                max-width: 560px;
                padding: 10px 15px;

                .scaler-header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;

                    span {
                        font-weight: 600;
                        font-size: 11pt;
                    }
                }

                .scaler-instructions {
                    width: 100%;
                }

                .scaler-options {
                    width: 100%;
                    margin: 10px 0;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: space-between;

                    span {
                        width: 35px;
                        height: 35px;
                        background-color: white;
                        color: black;
                        border-radius: 25px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 12pt;
                        font-weight: 600;
                        transition: background-color 0.3s ease;

                        &.selectedMultiplier {
                            background-color: #4DA9FF;
                        }

                        
                    }
                }
            }

            @media only screen and (max-width: 740px) {
                &.scale {
                    width: 100%;
                    max-width: unset;
                }
            }

            &:last-of-type {
                margin-right: 0;
            }
        }

        .chip-fraction {
            padding-left: 5px;
        }
    }

    .expanded-notes {
        width: calc(100% - 20px);
        max-width: 710px;
        margin: 0 10px;
        margin-bottom: 25px;

        .notes-toggle {
            display: flex;
            align-items: center;
            // font-weight: 500;
    
            svg {
                font-size: 22pt;
                color: black;
            }
        }

        .notes-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-weight: 600;
            font-family: 'Inter', sans-serif;
            font-size: 16pt;
            margin-bottom: 15px;
            cursor: pointer;

            svg {
                font-size: 18pt;
            }

            .title {
                display: flex;
                align-items: center;

                svg {
                    font-size: 22pt;
                    margin-right: 10px;
                }
            }

            .close-notes {
                display: flex;
                align-items: center;
                cursor: pointer;
                
                svg {
                    color: black;
                    transition: color 0.4s ease;

                    body.hasHover &:hover {
                        color: #606060;
                    }
                }
            }
        }

        .individual-note {
            font-family: 'Inter';
            font-size: 14pt;
            margin-bottom: 15px;
            display: flex;
            align-items: flex-start;
            animation: slide-in-note 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

            @keyframes slide-in-note {
                0% {
                    transform: translateY(15px);
                    opacity: 0;
                }
                100% {
                    transform: translateY(0);
                    opacity: 1;
                }
            }

            svg {
                font-size: 18pt;
                margin-right: 5px;
            }
        }
    }
}

.recipe-content {
    display: flex;
    margin-top: 15px;
    max-width: 1200px;
    margin-bottom: 50px;
}

.images {
    width: 50%;
    margin-left: 10px;

    section {
        position: relative;

        .no-main-img-placeholder {
            width: 100%;
            border-radius: 25px;
            aspect-ratio: 16 / 9;
            object-fit: cover;
            background-color: #b8dcff;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            margin: 0;
        
            svg {
                font-size: 32pt;
                color: #4DA9FF;
                margin-bottom: 10px;
            }
            
            span {
                font-size: 24pt;
                font-weight: 300;
                color: #4DA9FF; 
                font-family: 'Bitter', 'Inter', sans-serif;
            }
        }

        .additional-pics-toggle {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            font-size: 18pt;
            bottom: 0;
            left: 0;
            margin: 10px;
            margin-bottom: 13px;
            padding: 15px;
            background-color: #9BCFFF;
            transition: background-color 0.4s ease;
            border-radius: 50px;
            cursor: pointer;

            body.hasHover &:hover {
                background-color: #b4dbff;
            }
        }

        img {
            width: 100%;
            border-radius: 25px;
            aspect-ratio: 16 / 9;
            object-fit: cover;
            background-color: #b8dcff;
        }
    }

    .additional-pic {
        margin-top: 10px;
    }
    


    div {
        font-family: 'Inter', sans-serif;
        font-weight: 500;
        font-size: 12pt;
        margin: 10px;
        text-align: center;
        line-height: 18pt;

        span {
            color: #4DA9FF; 
            transition: color 0.4s ease;
            cursor: pointer;

            body.hasHover &:hover {
                color: #73bbff;
            }
        }
    }
}

.substitution-link {
    margin: 35px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
        font-weight: 400;
        font-size: 14pt;
        font-style: italic;
        margin: 0;
    }

    button {
        margin-top: 10px;
        
    }
}

.ingredients-instructions {
    width: 50%;
    margin-right: 10px;

    .data-toggle {
        height: 45px;
        background-color: #9BCFFF;
        border-radius: 45px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        position: relative;

        span {
            font-family: 'Inter', sans-serif;
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: center;
            white-space: nowrap;
            text-align: center;
            cursor: pointer;
            transition: all 0.3s ease;
            z-index: 50;
            width: 50%;
            height: 100%;
        }

        .tab-background {
            position: absolute;
            left: 0;
            background-color: #4DA9FF;
            width: 50%;
            height: 35px;
            border-radius: 45px;
            margin: 5px;
        }
    }
}

.ingredient-subsection, .instruction-subsection {
    margin-bottom: 35px;

    .subsection-title {
        margin-bottom: 20px;
        font-family: 'Inter', sans-serif;
        font-size: 16pt;
        font-weight: 600;
    }
}

.ingredients, .instructions {
    margin: 25px 10px;
}

.ingredients, .instructions {
    font-family: 'Inter', sans-serif;
    font-size: 14pt;

    .ingredient-item, .instruction-item {
        margin-bottom: 15px;

        span {
            font-weight: 600;
        }
    }

    .instruction-item {
        margin-bottom: 25px;
        white-space: pre-wrap;
    }
}



@media only screen and (max-width: 740px) {
    .recipe-content {
        display: flex;
        flex-direction: column;
    }

    .recipe-page {
        .recipe-header-controls {
            .back-button {
                padding: 0 20px;
    
                svg {
                    margin-right: 0;
                }
            }
        }

        .recipe-author {
            margin-left: 0px;
        }

        .recipe-chips {
            margin: 10px 0;
        }
        

        h2 {
            margin-top: 15px;
            font-size: 28pt;
        }
    }

    .ingredients-instructions {
        margin-right: 0;
        width: 100%;
        margin-bottom: 25px;
    }

    .images {
        margin-left: 0;
        width: 100%;
    }
}